import React from "react"
import styled from "styled-components"
import Layout from "../../../components/Layout"
import SEO from "../../../components/Seo"

const Container = styled.div`
  max-width: 1000px;
  margin: 40px auto 0;
`

interface Props {
  title: string
  content: string
}

const Page = ({ title, content }: Props) => {
  return (
    <Layout>
      <SEO title={title} description={content} />
      <Container>
        <h1>{title}</h1>
        <div dangerouslySetInnerHTML={{ __html: content }} />
      </Container>
    </Layout>
  )
}

export default Page
