import React from "react"
import { graphql } from "gatsby"
import Page from "../social-supermarket/components/page/Page"

const PageTemplate = ({ data: { wpPage } }) => {
  return <Page {...wpPage} />
}
export default PageTemplate

export const query = graphql`
  query ($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      content
      slug
    }
  }
`
